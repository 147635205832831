import React from 'react'
import Select, {
    RefSelectProps,
    SelectProps,
    SelectValue,
} from 'antd/lib/select'

const DefaultSelect = <VT extends SelectValue = SelectValue>(
    props: SelectProps<VT>,
    ref: React.Ref<RefSelectProps>
) => {
    return (
        <Select<VT>
            ref={ref}
            allowClear
            showSearch
            optionFilterProp="label"
            style={{ width: '100%' }}
            {...props}
        >
            {props.children}
        </Select>
    )
}

const { Option, OptGroup } = Select
const SelectRef = React.forwardRef(DefaultSelect) as <
    VT extends SelectValue = SelectValue
>(
    props: SelectProps<VT> & { ref?: React.Ref<RefSelectProps> }
) => React.ReactElement

type InternalSelectType = typeof SelectRef
interface SelectInterface extends InternalSelectType {
    SECRET_COMBOBOX_MODE_DO_NOT_USE: string
    Option: typeof Option
    OptGroup: typeof OptGroup
}

const DSelect = SelectRef as SelectInterface

DSelect.SECRET_COMBOBOX_MODE_DO_NOT_USE = 'SECRET_COMBOBOX_MODE_DO_NOT_USE'
DSelect.Option = Option
DSelect.OptGroup = OptGroup

export default DSelect
