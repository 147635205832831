import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { message } from 'antd'
import qs from 'qs'
import { getUUID } from 'src/utils'
import sha1 from 'js-sha1'

interface HttpResponse<T = any> {
    code: string
    message: string
    data?: T,
    page?: COMMON.Page
}

interface requestConfig extends AxiosRequestConfig {
    nonEmpty?: boolean
    isOperator?: boolean
    isFormData?: boolean
}

// interface headerConfig {
//     timestamp:number
//     sn: string,
//     code: string
// }

const service = axios.create({
    baseURL: process.env.REACT_APP_BASE_API,
    timeout: 60000 * 60
    // withCredentials: true, // send cookies when cross-domain requests
})

// request interceptor
service.interceptors.request.use(
    (config: requestConfig & any) => {
        // 登陆后权限验证
        const timesTamp = new Date().getTime()
        const token = localStorage.getItem('operator_token');
        const sn = getUUID()
        const combination = `${sn}${config.url}${timesTamp}`
        const reserveString = combination.split("").reverse().join("")
        const sha1String = sha1(reserveString)
        const headerConfig: any = {
            timestamp:timesTamp,
            sn,
            code: sha1String,
            source: 'source-admin'
        }
        for (const key of Object.keys(headerConfig)) {
            config.headers[key] = headerConfig[key]
        }
        if (token) {
            config.headers['Authorization'] = token
        } 
        config = q(config)
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
// response interceptor
service.interceptors.response.use(
    async (response: AxiosResponse<HttpResponse, any> & any) => {
        const res = response.data
        const whiteCodes = ['AUTH_2008','AUTH_2005' ]
        if (response.config.responseType === 'blob') {
            try {
                const result: HttpResponse = await blobToText(res)
                errorMsg(result.message)
                return Promise.reject(result.message || 'error')
            } catch (error) {
                console.log('truly file')
                const disposition = response.headers['content-disposition']
                const filename = decodeURIComponent(escape(disposition && disposition.split('filename=')[1] || 'unkown'))
                return { filename, data: res }
            }
        } else if (whiteCodes.includes(res.code)) {
            return res
        } else if (res.code !== '00000') {
            errorMsg(res.message)
            return Promise.reject(res.code === 'SVW_4002' ? res : (res.message || 'error'))
        }
        if ((response.config as any).isOperator) {
            message.success('操作成功')
        }
        return res
    },
    error => {
        if (error.response) {
            switch (error.response.status) { // 401 重新登录
            case 401:
                errorMsg('请重新登录')
                localStorage.removeItem('operator_token')
                location.href = '/login'
            }
        }
        return Promise.reject(error)
    }
)

// get的参数序列化
function q(config: requestConfig & any) {
    config.method === 'get' && config.params && (config.paramsSerializer = (params: any) => {
        return qs.stringify(params, { indices: false })
    })
    return config
}

function blobToText(data: any): Promise<HttpResponse> {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsText(new Blob([data]))
        fileReader.onload = function () {
            try {
                const result = JSON.parse(this.result as string)
                if (result) {
                    resolve(result)
                } else {
                    reject(new Error())
                }
            } catch (e) {
                reject(e)
            }
        }
    })
}

function errorMsg(msg?: string) {
    message.error(msg || 'error')
}

const http = <T = any>(options: requestConfig) => {
    return service.request<any, HttpResponse<T>>(options)
}

export default http
