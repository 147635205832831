import React, { useEffect } from 'react'
import Authorization from './authorization'
import { useStore } from 'src/hooks/useStores'

function App() {
    const rootStore = useStore('rootStore')
    const userStore = useStore('userStore')

    useEffect(() => {
        if (location.pathname !== '/login') {
            userStore.getUserInfo()
        }
        return () => {
            rootStore.setToLocal()
        }
    })
    return <Authorization />
}

export default App
