
import React, { Suspense, lazy } from 'react'
import BaseLayout from 'src/layout/BaseLayout'

export const lazyLoad = (src: any) => (
    <Suspense fallback={<div />}>{React.createElement(lazy(src))}</Suspense>
)

export const asyncRoutes = [
    {
        path: '/',
        element: <BaseLayout />,
        children: [
            {
                path: '/',
                element: lazyLoad(
                    () => import('src/views/dashboard')
                ),
                meta: { title: '首页', icon: 'dashboard' },
            },
            {
                path: '/management',
                meta: { title: '客户管理', icon: '4s' },
                redirect: '/management/tenant',
                children: [
                    {
                        path: '/management/tenant',
                        element: lazyLoad(
                            () => import('src/views/management/tenant')
                        ),
                        meta: { title: '租户管理', icon: '' },
                    },
                    {
                        path: '/management/store',
                        element: lazyLoad(
                            () => import('src/views/management/store')
                        ),
                        meta: { title: '门店管理', icon: '' },
                    },
                    {
                        path: '/management/order',
                        element: lazyLoad(
                            () => import('src/views/management/order')
                        ),
                        hidden: true,
                        meta: { title: '订购管理', icon: '' },
                    },
                ],
            },
            {
                path: '/data',
                meta: { title: '基础数据维护', icon: '4s' },
                redirect: '/data/part-category',
                children: [
                    {
                        path: '/data/part-category',
                        element: lazyLoad(
                            () => import('src/views/dataMaintain/partCategory')
                        ),
                        meta: { title: '配件类型设置', icon: '' },
                    },
                    {
                        path: '/data/rule-setting',
                        element: lazyLoad(
                            () => import('src/views/dataMaintain/ruleSetting')
                        ),
                        meta: { title: '配件推荐规则设置', icon: '' },
                    },
                    {
                        path: '/data/maintain-category',
                        element: lazyLoad(
                            () => import('src/views/dataMaintain/maintainCategory')
                        ),
                        meta: { title: '维修类型设置', icon: '' },
                    },
                    {
                        path: '/data/carSeries-setting',
                        element: lazyLoad(
                            () => import('src/views/dataMaintain/carseriesSetting')
                        ),
                        meta: { title: '车系设置', icon: '' },
                    },
                ],
            },
            {
                path: '/relation-data',
                meta: { title: '关系数据维护', icon: '4s' },
                children: [
                    {
                        path: '/relation-data/part-list',
                        element: lazyLoad(
                            () => import('src/views/dataMaintain/partList')
                        ),
                        meta: { title: '配件映射关系维护', icon: '' },
                    },
                    {
                        path: '/relation-data/maintain-list',
                        element: lazyLoad(
                            () => import('src/views/dataMaintain/maintainList')
                        ),
                        meta: { title: '维修映射关系维护', icon: '' },
                    },
                    {
                        path: '/relation-data/cartype-mapping',
                        element: lazyLoad(
                            () => import('src/views/dataMaintain/cartypeMapping')
                        ),
                        meta: { title: '车型映射关系维护', icon: '' },
                    },
                ],
            },
            {
                path: '/appVersion',
                meta: { title: 'App版本管理', icon: '4s' },
                element: lazyLoad(
                    () => import('src/views/appSystem/index')
                ),
               
            },
        ],
    },
]
