import React, { useEffect, useState } from 'react'
import { matchRoutes, useLocation } from 'react-router'
import type { MenuProps, MenuTheme } from 'antd'
import { Menu, Switch } from 'antd'
import { routes } from 'src/routers'
import { NavLink } from 'react-router-dom'
import SMenu from './SMenu'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import cx from 'classnames'

import {
    AppstoreOutlined,
    MailOutlined,
    SettingOutlined,
} from '@ant-design/icons'

type MenuItem = Required<MenuProps>['items'][number]
// import useStores from 'src/stores'
// import SvgIcon from 'src/views/components/SvgIcon'
import styles from '../index.module.less'
import { AnyObject } from 'yup/lib/object'
// import SMenu from './SMenu'

interface routerItem {
    name: string
    path: string
    children: routerItem[]
}

const SideMenu = ({ collapsed, ...props }: { collapsed: boolean }) => {
    // const { User } = useStores()
    const location = useLocation()
    const [sideMenu, setSideMenu] = useState<any[]>([])
    const [activeMenu, setActiveMenu] = useState<any[]>([])
    // const [current, setCurrent] = useState('1')

    const getSubMenu = (list: any[]) => {
        console.log(list)
        return list[0].route.children.map((item: any) => {
            const hasC = !item.children
            // const menuUrl = item.fullpath
            // const menuName = item.meta.title
            // const { menuUrl, menuName } = item.menu
            const { path } = item
            return hasC ? (
                <Menu.Item
                    key={path}
                    // icon={meta && meta.icon ? Icon(meta.icon) : null}
                >
                    <NavLink to={path}>{item.meta.name}</NavLink>
                </Menu.Item>
            ) : (
                <Menu.SubMenu
                    key={path}
                    title={item.meta.name}
                    // icon={meta && meta.icon ? Icon(meta.icon) : null}
                    popupClassName="side-menu-popup"
                >
                    {getSubMenu(item.children)}
                </Menu.SubMenu>
            )
        })
    }
    // console.log(items)

    useEffect(() => {
        // console.log('location', location)
        const matches: any = matchRoutes(routes, location) // 返回匹配到的路由数组对象，每一个对象都是一个路由对象
        const sm = (matches && matches[0].route.children) || []
        // console.log('matches22222222222', matches, sm)
        setSideMenu(sm)
        // setActiveMenu()
        // 	const matches = matchRoutes(User.menuTree, location)
        	const invisible = matches === null || (matches![matches.length - 1] as any).route.hidden
        // 	const sm = (matches && matches[0].route.children) || []
        	const am = (matches || []).map((m: any) => m.pathname)
        // console.log(am, '====am')
        // 	setSideMenu(invisible ? [] : sm)
        	setActiveMenu(invisible ? [] : am.slice(1))
        // // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (
        <div className={cx(styles['side-menu-wrapper'], collapsed ? styles['collapsed']: styles['uncollapsed'])}>
            {/* <div className="side-btn" onClick={() => setCollapsed(!collapsed)}>
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </div> */}
            <div className={styles['sidebar-logo-container']}>
                <SvgIcon name="lambda" />
                {
                    !collapsed && <h1>运营管理平台</h1>
                }
            </div>
            <SMenu
                sideMenu={sideMenu}
                activeMenu={activeMenu}
                collapsed={collapsed}
            />
            {/* <SMenu sideMenu={sideMenu} activeMenu={activeMenu} collapsed={collapsed}  /> */}
        </div>
    )
}

export default SideMenu
