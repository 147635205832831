import React from 'react'
import { useRoutes, Link } from 'react-router-dom'
import { asyncRoutes, lazyLoad } from './routers'

const constantRouters = [
    {
        path: '/login',
        element: lazyLoad(() => import('src/views/login/index')),
    },
    {
        path: '*',
        element: <NoMatch />,
    },
]

export const routes = [...asyncRoutes, ...constantRouters]

const Routers = () => {
    return <>{useRoutes(routes)}</>
}

function NoMatch() {
    return (
        <div>
            <h2>It looks like you're lost...</h2>
            <p>
                <Link to="/">Go to the home page</Link>
            </p>
        </div>
    )
}

export default Routers
