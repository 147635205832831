// 导出文件
export const exportFile = ({fileData, fileName}: any) => {
    const url = window.URL.createObjectURL(new Blob([fileData]))
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', `${fileName}`)
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(link.href)
    document.body.removeChild(link)
}

// 获取uuid
export const getUUID = () => {
    const s: any = []
    const hexDigits = '0123456789abcdef'
    for (let i = 0; i < 32; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23]

    const uuid = s.join('')
    return uuid
}

const setSession = (name: string, value: any) => {
    if (!name) return
    const values = JSON.stringify(value)
}

export const stroage = {
    session: {
        set: function(name: string, value: any) {
            setSession(name, value)
            window.sessionStorage.setItem(name, value)
        },
        get: function(name: any) {
            if (!name) return;
            return window.sessionStorage.getItem(name)
        },

        remove: function(name: any) {
            if (!name) return;
            window.sessionStorage.removeItem(name)
        },

        clear: function() {
            localStorage.clear();
        }
    },
    local: {
        set: function(name: string, value: any) {
            setSession(name, value)
            window.localStorage.setItem(name, value)
        },
        get: function(name: any) {
            if (!name) return;
            if (name === 'userInfo') {
                return JSON.parse((window.localStorage.getItem(name)) as any)
            }
            return window.localStorage.getItem(name)
        },

        remove: function(name: any) {
            if (!name) return;
            window.localStorage.removeItem(name)
        },

        clear: function() {
            localStorage.clear();
        }
    }
}

// 业务
interface DictItem {
    label: string
    value: any
}
export const mapLabelFromDict = (dict: DictItem[], value: any) => {
    if (!value) return ''
    const data = dict.filter((f) => f.value === value)
    return data.length ? data[0].label : ''
}

// 判断null值
export function isNull (str: any){
    return !str && typeof str !== "undefined" && str !== 0
}
// 判断null后 显示-
export function showNull (str: any){
    if (!str && typeof str !== "undefined" && str !== 0) {
        return '-'
    } else {
        return str
    }
}
