import { Layout, Modal, Popover, Tooltip, Breadcrumb } from 'antd'
import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
// import useStores from 'src/stores'
// import SvgIcon from 'src/views/components/SvgIcon'
import styles from '../index.module.less'
import styled from 'styled-components'
import { observer, inject } from 'mobx-react'
import store from 'src/store'
import { CaretDownOutlined } from '@ant-design/icons'
import { stroage } from 'src/utils/index'
import UpdatePasswordDialog from './UpdatePassword'

// import APPQR from './QR'
import { logout, updatePassword } from 'src/api/login'
const { Header } = Layout
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import {
    HashRouter,
    Link,
    Route,
    Routes,
    useLocation,
    useParams,
    matchRoutes,
} from 'react-router-dom'

import { routes } from 'src/routers'
import { useStore } from 'src/hooks/useStores'
import { UpdatePasswordParam } from 'src/types/login'

const UserOpera = styled.div`
    li {
        list-style: none;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #f0f0f0;
        text-align: center;
        color: #33475b;
        cursor: pointer;
        &:hover {
            color: #2878ff;
        }
    }
`

const HeaderNav = ({ expand, collapsed, ...props }: { expand: () => void, collapsed: boolean }) => {
    const userStore = useStore('userStore')

    const navigate = useNavigate()
    const location = useLocation()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const matches: any = matchRoutes(routes, location.pathname)

    const breadcrumbItems = () => {
        return matches.map((item: any, i: number) => {
            if (i === 0 && matches.length > 1 && matches[1].route.path === '/') {
                return null
            }
            return (
                <Breadcrumb.Item key={i}>
                    <Link to={item.route.redirect || item.route.path}>
                        {item.route.meta ? item.route.meta.title : '首页'}
                    </Link>
                </Breadcrumb.Item>
            )
        })
    }

    const handleLogout = () => {
        const confirmCB = async () => {
            try {
                await logout()
                localStorage.removeItem('operator_token')
                navigate('/login')
            } catch (error) {
                console.log(error)
            }
        }
        Modal.confirm({
            centered: true,
            content: '是否确认退出登录？',
            title: '退出提示',
            onOk: confirmCB,
            okText: '确认',
            cancelText: '取消',
        })
    }

    const handleUpdatePassword = () => {
        setOpen(true)
    }

    const UserOperation = () => (
        <UserOpera>
            <ul>
                <li onClick={handleUpdatePassword}>修改密码</li>
                <li onClick={handleLogout}>退出登录</li>
            </ul>
        </UserOpera>
    )

    const handleConfirm = async (values: UpdatePasswordParam) => {
        try {
            setLoading(true)
            await updatePassword(values)
            setLoading(false)
            setOpen(false)
            localStorage.removeItem('operator_token')
            navigate('/login')
        } catch (error) {
            setLoading(false)
        }
    }

    const handleCancel = () => {
        setOpen(false)
    }

    return (
        <Header className={styles['layout-header']}>
            {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                    className: 'trigger',
                    onClick: expand
                }
            )}
            <div className={styles['breadcrumb']}>
                <Breadcrumb>{breadcrumbItems()}</Breadcrumb>
            </div>
            <div className={styles.infomation}>
                <div className={styles.avatar}>
                    <SvgIcon name="avatar" />
                </div>
                <div className={styles.account}>{userStore.userInfo.username}</div>
                {/* dialog */}
                <div className={styles.logout}>
                    <Popover
                        content={<UserOperation />}
                        overlayStyle={{ width: '130px' }}
                        overlayClassName="nopadding"
                        placement="bottomLeft"
                        trigger="hover"
                        arrowPointAtCenter
                    >
                        <CaretDownOutlined />
                    </Popover>
                </div>
            </div>
            <UpdatePasswordDialog
                loading={loading}
                open={open}
                cancel={handleCancel}
                confirm={handleConfirm}
            />
        </Header>
    )
}
export default observer(HeaderNav)
