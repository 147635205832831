import {
    observable,
    action,
    // computed,
    // makeObservable,
    makeAutoObservable,
} from 'mobx';

import { getCurrentUserInfo } from 'src/api/login'

export class UserStore {

    userInfo = {}
    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    setUserInfo (user: any) {
        this.userInfo = user
    }

    async getUserInfo () {
        const data = await getCurrentUserInfo()
        this.setUserInfo(data.data)
    }
}
export default UserStore;
