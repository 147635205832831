import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import HeaderNav from './HeaderNav/index'
import styled from 'styled-components'
import SideMenu from './SideMenu'
import styles from './index.module.less'

const Main = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    overflow-y: overlay;
    background: #fff;
`
const BaseLayout: React.FC = () => {
    const [collapsed, setCollapsed] = useState(false)

    const handleExpand = () => {
        setCollapsed(!collapsed)
    }
    return (
        <div className={styles['layout-contain']}>
            <SideMenu collapsed={collapsed} />
            <Main>
                <HeaderNav expand={handleExpand} collapsed={collapsed} />
                <div className={styles['outlet-box']}>
                    <Outlet />
                </div>
            </Main>
        </div>
    )
}

export default BaseLayout
