import React from 'react'
import cx from 'classnames'
import './icon.css'

interface IconProps {
    name: string
    className?: string
    onClick?: () => void
    style?: React.CSSProperties
    fill?: string
}

const Icon = ({ name, className, onClick, style, fill }: IconProps) => {
    return (
        <svg
            className={cx('svg-icon', className)}
            aria-hidden={true}
            onClick={() => onClick && onClick()}
            style={fill ? { fill } : style}
        >
            <use xlinkHref={`#${name}`} />
        </svg>
    )
}

export default Icon
