import Select from './Select'
import Table from './Table'
import Input from './Input'
import Tabs from './Tabs'
import DatePicker from './rangePicker'

const D = {
    Select,
    Table,
    Input,
    Tabs,
    DatePicker,
}
export default D
