import React, { useEffect } from 'react'
import { Form, Button } from 'antd'
import { useFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import Dialog from 'src/components/Modal'
import D from 'src/components/AntD'

import { UpdatePasswordParam } from 'src/types/login'

interface Iprops {
    open: boolean
    confirm: (values: UpdatePasswordParam) => void
    cancel: () => void
    loading: boolean
}

const UpdatePassword = ({ open, confirm, cancel, loading }: Iprops) => {

    const [antForm] = Form.useForm()

    const schemaObj = {
        oldPassword: Yup.string().required('请填写原密码'),
        newPassword: Yup.string().required('请填写新密码'),
    }

    const validationSchema = Yup.object(schemaObj)
    const formik: FormikProps<any> = useFormik<any>({
        initialValues: {},
        validationSchema,
        onSubmit: values => {
            confirm(values)
        },
    })

    const getValidProps = (k: keyof UpdatePasswordParam) => {
        return {
            validateStatus: formik.touched[k] && formik.errors[k] ? 'error' : "",
            help: formik.touched[k] && formik.errors[k] ? formik.errors[k] : null
        }
    }

    useEffect(() => {
        formik.handleReset()
        antForm.resetFields()
    }, [open])

    const onFinish = (values: UpdatePasswordParam) => {
        formik.setValues(values)
        formik.handleSubmit()
    }

    const orderInfo: COMMON.SearchForm<UpdatePasswordParam>[] = [
        {
            label: '原密码',
            name: 'oldPassword',
            ...getValidProps('oldPassword'),
            children: <D.Input {...formik.getFieldProps('oldPassword')} placeholder='请填写原密码' />
        },
        {
            label: '新密码',
            name: 'newPassword',
            ...getValidProps('newPassword'),
            children: <D.Input {...formik.getFieldProps('newPassword')} placeholder='请填写新密码' />
        }
    ]

    const handleClose = () => {
        cancel()
    }

    const ActionNodes = (
        <>
            <Button onClick={handleClose}>取消</Button>
            <Button type="primary" loading={loading} onClick={() => antForm.submit()}>确定</Button>
        </>
    )

    return (
        <Dialog
            open={open}
            title="修改密码"
            closable={true}
            action={ActionNodes}
            onCancel={handleClose}
            width="800px"
            destroyOnClose
        >
            <Form
                className='dialog-form'
                form={antForm}
                initialValues={{}}
                onFinish={onFinish}
                autoComplete="off"
            >
                <div className='flex flex-wrap'>
                    {
                        orderInfo.map((f) => <Form.Item style={{width: '46%'}} labelCol={{span: 8}} key={f.label} { ...f } />)
                    }
                </div>
            </Form>
        </Dialog>
    )
}

export default UpdatePassword