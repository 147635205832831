import UserStore from './stores/userStore';
import { observable, action, makeAutoObservable } from 'mobx';


class RootStore {
    userStore: UserStore

    constructor() {
        // makeAutoObservable(this);
        makeAutoObservable(this, {}, { autoBind: true })
        const local = sessionStorage.getItem('cache') || '{}';
        const cache = JSON.parse(local);
        this.userStore = Object.assign(new UserStore(), cache.userStore);
    }

    setToLocal() {
        sessionStorage.setItem('cache', JSON.stringify(this));
    }
}

export default new RootStore()