import React from 'react'
import { useRoutes, Link, useNavigate } from 'react-router-dom'
import { routes } from '../routers/index'

const Authorization = () => {
    const navigate = useNavigate()
    const auth = localStorage.getItem('operator_token')
    if (!auth && location.pathname !== '/login') {
        navigate('/login')
        return <div />
    }
    return <div>{useRoutes(routes)}</div>
}

export default Authorization
