import React from 'react'
import { Tabs, TabsProps } from 'antd'
import './index.module.less'

const DefaultTab = (props: TabsProps) => {
    const { items, ...rest } = props
    return <Tabs items={items} {...rest} />
}

export default DefaultTab
