import request from './request'
import { LoginParam, MobileMessageParam, LoginByMobileParam, UpdatePasswordParam } from '../types/login'

export const loginRequest = (data: LoginParam) => {
    return request({
        url: '/auth-service/authorization/admin/login/password',
        method: 'post',
        data,
    })
}

export const loginRequestByMobile = (data: LoginByMobileParam) => {
    return request({
        url:'/auth-service/authorization/admin/login/verifyCode',
        method: 'post',
        data
    })
}

// 获取图形验证码 getCaptchaImg
export const getCaptchaImg = () => {
    return request({
        url: '/auth-service/authorization/common/captcha',
        method: 'get',
        params: {
            width: 220,
            height: 58
        }
    })
}

export const getCurrentUserInfo = () => {
    return request({
        url: '/auth-service/authorization/admin/info/current',
        method: 'get'
    })
}


export const mobileMessageRequest = (data: MobileMessageParam) => {
    return request({
        url:'/auth-service/authorization/admin/login/verifyCode/send',
        method: 'post',
        data
    })
}

export const logout = () => {
    return request({
        url:'/auth-service/authorization/admin/logout',
        method: 'post'
    })
}

export const updatePassword = (data: UpdatePasswordParam) => {
    return request({
        url: '/auth-service/authorization/admin/login/password/update',
        method: 'post',
        data
    })
}
